import { Box, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { PlatformElevatedCard } from 'components/PlatformElevatedCard';
import { ReactNode } from 'react';

type EntryScreenCardProps = {
  title: string;
  error?: string;
  children: ReactNode;
};

export const EntryScreenCard = ({
  title,
  error,
  children,
}: EntryScreenCardProps) => {
  return (
    <PlatformElevatedCard sx={paperStyle}>
      <Typography variant="h5" mb={4}>
        {title}
      </Typography>
      {error && (
        <Box mb={3}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      {children}
    </PlatformElevatedCard>
  );
};

const paperStyle = {
  padding: '3rem',
  maxWidth: '565px',
  width: '100%',
};
