import { Box, Container, Typography } from '@mui/material';
import { mailToSupport } from 'common/consts';
import { Theme } from 'common/enums';
import { Link } from 'components/Inputs/Link';
import { useCustomMediaQuery } from 'hooks';
import { useTheme } from 'next-themes';
import Head from 'next/head';
import Img from 'next/image';
import { ReactNode, useMemo } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import { getProcessEnv } from 'utils';

const processEnv = getProcessEnv();

type EntryScreenProps = {
  headersTitle: string;
  children: ReactNode;
  supportLabel?: string;
  supportLink?: string;
  supportLinkLabel?: string;
};

export const EntryScreen = ({
  headersTitle,
  children,
  supportLabel,
  supportLink = mailToSupport,
  supportLinkLabel,
}: EntryScreenProps) => {
  const { lessThenSm } = useCustomMediaQuery();
  const { theme } = useTheme();

  const logo = useMemo(
    () =>
      theme === Theme.light
        ? processEnv.NEXT_PUBLIC_S3_LOGO
        : processEnv.NEXT_PUBLIC_S3_LOGO_DARK,
    [theme]
  );

  const { contactTechSupportLabel } = useCommonTranslations();

  return (
    <div>
      <Head>
        <title>{headersTitle}</title>
        <link rel="shortcut icon" href={processEnv.NEXT_PUBLIC_S3_MARK} />
      </Head>
      <Container maxWidth="sm">
        <Box
          height="100%"
          pt={10}
          pb={7.5}
          display="flex"
          flexDirection="column"
          justifyContent={lessThenSm ? 'flex-start' : 'center'}
          alignItems="center"
        >
          <Box
            mb={7.5}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            {logo && (
              <Img src={logo} alt="logo" width={211.8} height={65} priority />
            )}
          </Box>
          {children}
        </Box>
        {supportLabel && (
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Typography variant="body1">
              {supportLabel}{' '}
              <Link href={supportLink}>
                {supportLinkLabel || contactTechSupportLabel}
              </Link>
            </Typography>
          </Box>
        )}
      </Container>
    </div>
  );
};
