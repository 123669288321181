import { Paper, PaperProps } from '@mui/material';

export const PlatformElevatedCard = ({
  children,
  sx,
  ...props
}: PaperProps) => {
  return (
    <Paper elevation={8} sx={{ ...sx, ...paperSx }} {...props}>
      {children}
    </Paper>
  );
};

const paperSx = { boxShadow: '0px 30px 40px 4px rgba(0, 0, 0, 0.1)' };
