import { TextField } from '@mui/material';
import { useFormOptions } from 'common/formOptions';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { useIntl } from 'react-intl';

type EmailFieldProps = {
  register: UseFormRegister<any>;
  errors: FieldErrorsImpl<any>;
  fieldName?: string;
  disabled?: boolean;
};

export const EmailField = ({
  register,
  disabled,
  errors,
  fieldName = 'email',
}: EmailFieldProps) => {
  const intl = useIntl();
  const errorMsg = errors?.[fieldName]?.message as string;
  const { required, emailValidation } = useFormOptions();

  const label = intl.formatMessage({
    id: 'common.email',
    defaultMessage: 'Email',
  });

  return (
    <TextField
      label={label}
      disabled={disabled}
      error={!!errorMsg}
      helperText={errorMsg}
      {...register(fieldName, {
        required,
        validate: emailValidation,
      })}
    />
  );
};
