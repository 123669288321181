import { Stack } from '@mui/material';
import { ButtonContained } from 'components/Inputs/Button';
import { EmailField, PasswordField } from 'components/Inputs/TextField';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

type Form = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSubmitCallback: (email: string, password: string) => void;
  loading: boolean;
  disabled: boolean;
};

export const LoginForm = ({
  onSubmitCallback,
  loading,
  disabled,
}: LoginFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();

  const submitHandler = (data: Form) => {
    onSubmitCallback(data.email, data.password);
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Stack spacing={2}>
        <EmailField
          register={register}
          errors={errors}
          disabled={loading || disabled}
        />
        <PasswordField
          register={register}
          errors={errors}
          disabled={loading || disabled}
        />
        <ButtonContained
          size="large"
          type="submit"
          loading={loading}
          disabled={disabled}
        >
          <FormattedMessage id="nonAuth.logIn" defaultMessage="Log In" />
        </ButtonContained>
      </Stack>
    </form>
  );
};
