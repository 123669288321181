import { Stack, TextField, Typography } from '@mui/material';
import { ButtonContained } from 'components/Inputs/Button';
import { NameField, PasswordField } from 'components/Inputs/TextField';
import { useForm } from 'react-hook-form';
import { useNonAuthTranslations } from 'translations/hooks';

type Form = {
  name: string;
  password: string;
  confirmPassword: string;
};

type CreateAccountFormProps = {
  onSubmit: (name: string, password: string) => void;
  email: string;
  name: string;
  loading: boolean;
  disabled: boolean;
};

export const CreateAccountForm = ({
  onSubmit,
  email,
  name,
  loading,
  disabled,
}: CreateAccountFormProps) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      name,
      password: '',
      confirmPassword: '',
    },
  });

  const submitHandler = ({ name, confirmPassword }: Form) => {
    onSubmit(name, confirmPassword);
  };

  // translations
  const { emailLabel, createPassLabel, confirmPassLabel, createAccountLabel } =
    useNonAuthTranslations();

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Stack spacing={2}>
        <NameField register={register} errors={errors} disabled={loading} />
        <TextField label={emailLabel} value={email} disabled />
        <Typography>{createPassLabel}</Typography>
        <PasswordField register={register} errors={errors} disabled={loading} />
        <PasswordField
          register={register}
          errors={errors}
          disabled={loading}
          fieldName="confirmPassword"
          label={confirmPassLabel}
          comparePasswordTo={watch('password')}
        />
        <ButtonContained
          size="large"
          type="submit"
          loading={loading}
          disabled={disabled}
        >
          {createAccountLabel}
        </ButtonContained>
      </Stack>
    </form>
  );
};
