import { TextField, TextFieldProps } from '@mui/material';
import { useFormOptions } from 'common/formOptions';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { useIntl } from 'react-intl';

type NameFieldProps = TextFieldProps & {
  register: UseFormRegister<any>;
  errors: FieldErrorsImpl<any>;
  fieldName?: string;
  disabled?: boolean;
};

export const NameField = ({
  register,
  disabled,
  errors,
  fieldName = 'name',
  ...props
}: NameFieldProps) => {
  const intl = useIntl();
  const { textInputValidation } = useFormOptions();
  const errorMsg = errors?.[fieldName]?.message as string;

  const label = intl.formatMessage({
    id: 'common.name',
    defaultMessage: 'Name',
  });

  return (
    <TextField
      label={label}
      disabled={disabled}
      error={!!errorMsg}
      helperText={errorMsg}
      {...register(fieldName, textInputValidation)}
      {...props}
    />
  );
};
