import { TextField } from '@mui/material';
import { useFormOptions } from 'common/formOptions';
import { useCallback, useState } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNonAuthTranslations } from 'translations/hooks';
import TogglePasswordVisibility from '../TogglePasswordVisibility/TogglePasswordVisibility';

type PasswordFieldProps = {
  register: UseFormRegister<any>;
  errors: FieldErrorsImpl<any>;
  fieldName?: string;
  label?: string;
  disabled?: boolean;
  comparePasswordTo?: string;
};

export const PasswordField = ({
  register,
  disabled,
  errors,
  label,
  comparePasswordTo,
  fieldName = 'password',
}: PasswordFieldProps) => {
  const intl = useIntl();
  const { required, passwordOpts } = useFormOptions();
  const [showPassword, setShowPassword] = useState(false);
  const { passLabel: defaultLabel, passMismatch } = useNonAuthTranslations();

  const validate = useCallback(
    (v: string) => {
      if (comparePasswordTo) {
        return v === comparePasswordTo || passMismatch;
      }
    },
    [comparePasswordTo, passMismatch]
  );

  const errorMsg = errors?.[fieldName]?.message as string;

  return (
    <TextField
      label={label || defaultLabel}
      type={showPassword ? 'text' : 'password'}
      disabled={disabled}
      {...register(fieldName, {
        required,
        validate,
        ...passwordOpts,
      })}
      error={!!errorMsg}
      helperText={errorMsg}
      InputProps={{
        endAdornment: (
          <TogglePasswordVisibility
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        ),
      }}
    />
  );
};
